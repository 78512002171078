/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import Footer from "~components/Footer.jsx";
import Layout from "~components/Layout.jsx";
import SEO from "~components/SEO.jsx";
import BannerSection from "~components/sections/BannerSection.jsx";
import HeadingSection from "~components/sections/HeadingSection.jsx";
import TextTwoColumn from "~components/sections/TextTwoColumn.jsx";
import VideoSection from "~components/sections/VideoSection.jsx";
import MapSection from "~components/sections/MapSection.jsx";
import InstagramSection from "~components/sections/InstagramSection.jsx";

const SignUpASchoolPage = ({ data, location }) => {
  // ===========================================================================
  // variables

  const cms = data.sanitySignUpPage;

  // ===========================================================================
  // render

  return (
    <>
      <SEO
        customTitle={cms?.title}
        customDescription={cms?.seoDescription}
        customKeywords={cms?.seoKeywords}
        path={location.pathname}
      />

      <Layout>
        <BannerSection data={cms.atfBanner} />

        <HeadingSection data={cms.headerSectionOne} colorChange />

        <TextTwoColumn data={cms.textSectionTwoCol} />

        <HeadingSection data={cms.headerSectionTwo} />

        <VideoSection data={cms.videoURL} />

        <MapSection />

        <InstagramSection />
      </Layout>

      <Footer />
    </>
  );
};

export default SignUpASchoolPage;

export const query = graphql`
  query SignUpASchoolPage {
    sanitySignUpPage {
      title

      #

      atfBanner {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
        image {
          altText
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }

      #

      headerSectionOne {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
      }

      #

      textSectionTwoCol {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        columnOne
        columnTwo
        button {
          link
          text
        }
      }

      #

      headerSectionTwo {
        textColor {
          hex
        }
        backgroundColor {
          hex
        }
        header
        subheader
      }

      #

      videoURL {
        backgroundColor {
          hex
        }
        url
      }

      #

      seoDescription
      seoKeywords
    }
  }
`;
